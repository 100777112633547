import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

export const ContentButtonCss = `
  display: inline-block;
  color: var(--color-black);
  border-bottom: 2px solid var(--color-black);
  margin: 0 20px 0 0;
`;

export const BoldCopyCss = `
  font-family: var(--font-family-bold);
  letter-spacing: 0.06em;
  text-transform: uppercase;
  // font-size: 0.9em;
  margin: 0;
`;

export const BoldCopy = styled.div`
  display: initial;
  ${BoldCopyCss}
`;

const Content = styled.div`
  padding: 40px 20px;
  text-align: center;

  h1, h2, h3 {
    margin-bottom: 1em;
  }

  p:first-child {
    margin-block-start: 0px;
  }

  p:last-child {
    margin-block-end: 0px;
  }
  
  ${breakpoint("md")`
    padding: 50px 30px;
    padding: 50px 30px;

    h1, h2 {
      width: 100%;
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
    }
  `}

  ${breakpoint("lg")`
    padding: 90px 5vw;
  `}

  ${breakpoint("xl")`
    padding: 90px 8vw;
  `}

  strong {
    ${BoldCopyCss}
  }

  .button-link {
    ${ContentButtonCss}
  }
`;

export const ContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
`;

export const ContentHeader = styled.div`
  text-align: center;
  /* max-width: 220px;
  margin: 0 auto; */
`;

export const ContentDivider = styled.div`
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: var(--color-black);
`;

export const ContentImageContainer = styled.div`
  position: relative;
  height: inherit;
`;

export const ContentImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;

  background-image: url(${props => props.src});
  background-size: cover;
  background-position: 50% 50%;
  background-color: var(--color-pink);
  /* background-blend-mode: screen; */
`;


export default Content;
